var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "wishlist-button-add",
      class: { "wishlist-button-product": _vm.isProduct, isFav: _vm.isChecked },
      on: { click: _vm.addToWishlist },
    },
    [
      _vm.isChecked
        ? _c("i", { staticClass: "material-icons" }, [_vm._v("favorite")])
        : _c("i", { staticClass: "material-icons" }, [
            _vm._v("favorite_border"),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }